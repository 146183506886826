<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar msg="جزئیات نظرسنجی" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="cmp-items-wrapper">
        <template v-if="survey['image']">
          <img
            :src="survey['image']"
            class="vote__img"
            v-show="flip"
            @load="flip = !flip"
          />
          <img class="vote__img" src="@img/preload-logo.svg" v-if="!flip" />
        </template>
        <p class="vote__title" v-text="survey ? survey['text'] : '...'" />
      </div>
      <section :class="['vote', isDark && 'vote--dark']">
        <a
          class="chart-row"
          v-for="vote in votes"
          :key="vote['id']"
          @click="enterVoteItem(vote)"
        >
          <div class="chart-row-stat">
            <h4 class="chart-row__text">{{ vote['text'] }}</h4>
          </div>
          <div class="chart-bar-row">
            <a class="filter-link">
              <svg viewBox="0 0 512 512">
                <path
                  d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
                />
              </svg>
            </a>
            <div class="chart-bar">
              <i
                :style="{
                  width: `${
                    vote['count'] === 0
                      ? '5px'
                      : (vote['count'] / votersCount) * 100 + '%'
                  }`
                }"
              />
            </div>
          </div>
          <div class="stat-row">
            <h6>{{ vote['count'] }}</h6>
            <span>-</span>
            <h5>
              {{
                Math.round(
                  (vote['count'] === 0 ? 0 : vote['count'] / votersCount) * 100
                )
              }}%
            </h5>
          </div>
        </a>
      </section>
      <div class="credit-buttons" style="margin: 0 0 20px;">
        <button class="button button--vote" @click="enterComment">
          <span class="button__text">توضیحات {{ `(${commentCount})` }}</span>
        </button>
      </div>
      <section :class="['time-wrapper', isDark && 'time--dark']">
        <p v-if="diffDay > 1">
          از
          <span>{{ localizeDate(survey['created_at']) }}</span>
          تا
          <span v-text="diffDay" />
          روز
        </p>
        <p v-else>
          <span>{{ localizeDate(survey['created_at']) }}</span>
        </p>
        <count-down
          :endDate="applyTimeZone(survey['expired_at'])"
          :permission="!isDark"
          class="countdown"
        />
        <div class="offBtn" v-if="!isDark" @click="modals['confirm'] = true">
          <svg class="offBtn__svg" viewBox="0 0 512 512">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
        </div>
        <div class="offBtn offBtn--dark" v-if="isDark">
          <svg class="offBtn__svg" viewBox="0 0 512 512">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
          <span class="offBtn__text">
            {{ localizeDate(survey['cancel_at']) }}
          </span>
        </div>
      </section>
      <filters-table :filters="survey?.filters" :is-dark="isDark" />
      <a class="lottery-btn" @click="modals['lottery'] = true">
        <svg viewBox="0 0 750 512">
          <path
            d="M679.26 322.18l0 -109.75c0,-52.39 -42.86,-95.25 -95.24,-95.25l-105.2 0 1.2 -0.74c3.78,-2.32 7.22,-4.99 10.36,-8.13 24.78,-24.77 24.77,-64.96 0,-89.73 -24.77,-24.77 -64.96,-24.77 -89.73,0 -10.16,10.16 -11.57,16.38 -17.21,28.84l-0.89 1.95 -7.55 27.39 -7.54 -27.36 -0.9 -1.98c-5.64,-12.45 -7.05,-18.68 -17.21,-28.84 -24.77,-24.77 -64.96,-24.77 -89.73,0 -24.77,24.77 -24.78,64.96 0,89.73 3.14,3.14 6.58,5.81 10.36,8.13l1.2 0.74 -105.2 0c-52.38,0 -95.24,42.86 -95.24,95.25l0 109.75 39.17 0 0 94.58c0,52.38 42.86,95.24 95.24,95.24l339.69 0c52.39,0 95.25,-42.86 95.25,-95.24l0 -94.58 39.17 0zm-39.17 -27.19l-27.19 0 0 0.06 -475.81 0 0 -0.06 -27.18 0 0 0.06 -11.99 0 0 -82.62c0,-37.44 30.63,-68.06 68.06,-68.06l418.03 0c37.44,0 68.06,30.62 68.06,68.06l0 82.62 -11.98 0 0 -0.06zm-27.19 27.19l0 94.58c0,37.43 -30.62,68.05 -68.06,68.05l-339.69 0c-37.43,0 -68.06,-30.62 -68.06,-68.05l0 -94.58 475.81 0zm-334.06 -284.38c14.16,-14.16 37.13,-14.16 51.29,0 7.22,7.22 7.71,12.1 11.69,20.9l16.02 58.11 -0.68 -0.19c-18.32,-5.04 -65.77,-14.98 -78.32,-27.53 -14.16,-14.16 -14.16,-37.13 0,-51.29zm192.32 0c14.16,14.16 14.16,37.13 0,51.29 -12.55,12.55 -60,22.49 -78.33,27.53l-0.67 0.19 16.04 -58.17c3.96,-8.74 4.45,-13.62 11.67,-20.84 14.16,-14.16 37.13,-14.16 51.29,0z"
          />
        </svg>
        <span class="button__text">قرعه کشی</span>
      </a>
    </section>
    <failed-mode @refresh="getSurvey" v-else />
    <modal
      @modalClosed="modals['lottery'] = false"
      :is-open="modals['lottery']"
      title="قرعه کشی بین پاسخ دهندگان به کدام گزینه انجام شود؟"
    >
      <div class="plans-wrapper">
        <a
          class="plan-btn"
          v-for="item in lotteryItems"
          :key="item"
          @click="handleLottery(item)"
        >
          {{ item.text }}
        </a>
      </div>
    </modal>
    <modal-confirm
      :is-open="modals['confirm']"
      :is-loading="btnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleDisableSurvey"
      text='آیا از "ابطال" این نظرسنجی مطمئن هستید؟'
    />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { searchSurvey, disableSurvey } from '@service/SurveyService'
import dateConvertor from '@composable/dateConvertor'
import CountDown from '@common/CountDown'
import FiltersTable from '@common/FiltersTable'

export default {
  name: 'SurveyShow',
  props: ['id'],
  components: {
    FiltersTable,
    CountDown
  },
  setup(props) {
    /**************
     * MAIN ITEMS *
     **************/
    const store = useStore()
    const router = useRouter()
    const modals = reactive({ lottery: false, confirm: false })
    const btnLoading = ref(false)
    const flip = ref(false)

    // Fetch suryvey
    const survey = computed(() => store.state.survey.item)
    const pageStatus = computed(() => store.state.survey.pageStatus)
    const isDark = computed(() => store.state.survey.isDark)

    // Initial API call
    const getSurvey = () => {
      store.dispatch('survey/fetchItem', { part: 'survey', id: props.id })
    }
    getSurvey()

    // Disable survey
    const handleDisableSurvey = async () => {
      if (btnLoading.value) return

      btnLoading.value = true
      try {
        await disableSurvey(props.id)
        flip.value = false
        getSurvey()
        store.dispatch('addToast', {
          type: 'success',
          message: 'نظرسنجی با موفقیت ابطال گردید.'
        })
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
        }
      } finally {
        btnLoading.value = false
        modals['confirm'] = false
      }
    }

    // cleaning room
    onBeforeUnmount(() => {
      store.dispatch('survey/clearItem')
    })

    /****************
     * HANDLE VOTES *
     ****************/
    const votes = ref(null)
    const votersCount = ref(0)

    // Initialize votes
    watch(survey, () => {
      if (survey.value) {
        votes.value = survey.value['item']

        // calculate number of votes
        const sum = votes.value.reduce((acc, curr) => {
          return acc + curr.count
        }, 0)

        votersCount.value = sum
        lotteryItems.value = [...lotteryItems.value, ...votes.value]
      }
    })

    // Enter voteItem
    const enterVoteItem = (vote) => {
      if (vote.count === 0)
        return store.dispatch('addToast', {
          type: 'error',
          message: 'کسی به این گزینه رأی نداده است.'
        })

      store.commit('survey/SET_SURVEY_ITEM_TEXT', vote['text'])
      router.push({
        name: 'SurveyCustomer',
        query: { survey: props.id, surveyItem: vote.id },
        props: { itemText: vote['text'] }
      })
    }

    /***********************
     * ENTER INTO COMMENTS *
     ***********************/
    const commentCount = ref(0)
    const getCommentCounts = async () => {
      const searchOptions = { survey: props.id, has_text: true }
      try {
        const { data } = await searchSurvey(searchOptions)
        commentCount.value = data['count']
      } catch (ex) {
        console.log(ex)
      }
    }
    getCommentCounts()

    // enter comment routes
    const enterComment = () => {
      if (commentCount.value === 0)
        return store.dispatch('addToast', {
          message: 'تا کنون توضیحی ثبت نشده است.',
          type: 'error'
        })

      router.push({ name: 'SurveyComments' })
    }

    /******************
     * HANDLE LOTTERY *
     ******************/
    const lotteryItems = ref([{ id: -1, text: 'همه گزینه ها', count: null }])
    // Do lottery
    const handleLottery = async ({ id, count }) => {
      const lotteryOptions = { lotteryType: 'survey' }
      lotteryOptions['type'] = id === -1 ? 1 : 2
      lotteryOptions['id'] = id === -1 ? props.id : id
      lotteryOptions['customersCount'] = id === -1 ? votersCount.value : count

      modals['lottery'] = false
      if (lotteryOptions['customersCount'] === 0)
        return store.dispatch('addToast', {
          type: 'error',
          message: 'انجام قرعه‌کشی با صفر مشتری امکان‌پذیر نیست.'
        })
      store.dispatch('lottery/handleEnterLottery', lotteryOptions)
      router.push({ name: 'LotteryNew' })
    }

    /*****************
     * DOM INJECTION *
     *****************/
    // Time functions
    const { localizeDate, getRemainingDay, applyTimeZone } = dateConvertor()
    const diffDay = computed(() => {
      return getRemainingDay(
        survey.value['created_at'],
        survey.value['expired_at']
      )
    })

    return {
      flip,
      modals,
      survey,
      isDark,
      btnLoading,
      handleDisableSurvey,
      votes,
      lotteryItems,
      votersCount,
      localizeDate,
      getRemainingDay,
      applyTimeZone,
      enterComment,
      enterVoteItem,
      commentCount,
      handleLottery,
      getSurvey,
      pageStatus,
      diffDay
    }
  }
}
</script>

<style scoped lang="scss">
.cmp-wrapper {
  padding-bottom: 75px;
}
.cmp-wrapper--dark .vote__img {
  filter: saturate(0) opacity(0.6);
}
.cmp-wrapper--dark .vote__title {
  color: #777;
}
.main-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.vote__img {
  width: 70%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 15px;
}

.vote__title {
  font-size: 16px;
}

.vote {
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  width: 96%;
  background-color: #fff;
  direction: ltr;
  border-radius: 20px;
  padding: 20px 0 5px;
  .chart-row {
    position: relative;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 10px 8px 20px;
    margin: 10px 0;
    border-radius: 10px;
    cursor: pointer;
    // background-color: rgba(255, 255, 255, 0.03);

    .chart-bar-row {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;

      .filter-link {
        position: absolute;
        height: 20px;
        left: -30px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: auto 0;
        border-radius: 10px;
        width: 30px;
        cursor: pointer;
        svg {
          fill: #888;
          width: 20px;
          height: 20px;
          margin: 0 auto;
        }
      }

      .chart-bar {
        background-color: #e5e5e5;
        position: relative;
        border-radius: 2px;
        height: 7px;
        width: 100%;
        i {
          background: linear-gradient(to right, #555 0, #c69d4e 100%);
          position: absolute;
          height: 100%;
          left: 0;
          border-radius: 2px;
          z-index: 1;
          width: 100px;
        }
      }
    }
    .chart-row-stat {
      direction: ltr;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      .chart-row__text {
        line-height: 18px;
        text-align: justify;
        direction: rtl;
        margin: 0 0 4px;
        grid-row: 1/2;
        grid-column: 3/4;
        font-size: 14px;
        color: #111;
        width: 100%;
        // padding-top: 2px;
      }
    }
    .stat-row {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      h6 {
        direction: rtl;
        text-align: right;
        font-size: 14px;
        color: #555;
        margin: 0;
        &::after {
          content: 'نفر';
          color: #555;
          font-size: 12px;
          margin-right: 3px;
        }
      }
      h5 {
        text-align: right;
        color: #c69d4e;
        font-size: 14px;
        margin: 0;
        // width: 35px;
      }
      span {
        display: inline-block;
        margin: 0 3px;
        position: relative;
        bottom: 1px;
        color: #777;
      }
    }
  }
}

.time {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  background-color: #262626;
  border-radius: 15px;
  p {
    margin: 20px 0 0;
    color: #777;
    word-spacing: 5px;
    &.dark-theme {
      span {
        color: #777;
      }
    }
  }
  .countdown {
    margin-top: 0;
    padding-top: 10px;
    width: 100%;
  }
}

// Dark mode style
.cmp-wrapper--dark .vote__img {
  filter: saturate(0) opacity(0.6);
}
.cmp-wrapper--dark .vote__title {
  color: #777;
}
.cmp-wrapper--dark .button--vote {
  background-color: #181818;
  .button__text {
    color: #555;
  }
  .button__icon {
    fill: #555;
  }
}

.vote--dark {
  background-color: #181818;
  .chart-row .chart-row-stat .chart-row__text {
    color: #777;
  }
  .chart-row .chart-bar-row .chart-bar {
    background-color: #333;
  }
}

.plans-wrapper {
  background-color: #ddd;
  width: 100%;
  padding: 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .plan-btn {
    background-color: #fff;
    min-height: 80px;
    width: 94%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    span {
      color: #111;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.lottery-btn {
  cursor: pointer;
  margin-top: 20px;
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(#000, 0.1);
  svg {
    animation: blinking 1.3s infinite;
    fill: #c69d4e;
    height: 32px;
    margin-bottom: 12px;
  }
  span {
    animation: blinking 1.3s infinite;
  }
}
// change in common css
.cmp-items-wrapper {
  margin-bottom: 10px;
}
.credit-buttons {
  margin: 35px 0 30px;
  flex-direction: column;
}
.button--vote {
  width: 96%;
  background-color: #d0d0d0;
  .button__text {
    color: #111;
    font-size: 16px;
  }
  .button__icon {
    fill: #111;
  }
}
.time-wrapper {
  margin-bottom: 40px;
}
.time--dark {
  margin-bottom: 65px;
}
.countdown {
  margin-top: 0;
  padding-top: 10px;
  width: 100%;
}
.load-pending--show {
  height: calc(100vh - 130px);
}
</style>
