<template>
  <div class="comment">
    <p class="comment__text">
      {{ content }}
    </p>
    <div class="comment__profile">
      <div class="comment__img">
        <img v-show="flip" :src="image" @load="flip = true" />
        <svg
          v-if="!flip"
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
          viewBox="0 0 512 512"
        >
          <circle fill="#606062" cx="256" cy="256" r="256" />
          <path
            fill="#373435"
            d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
          />
        </svg>
      </div>
      <div class="comment__info">
        <h5 class="comment__name">
          {{ name }}
        </h5>
        <div class="comment__time">
          <time>{{ date }}</time>
          <time>{{ time }}</time>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyComment',
  props: {
    content: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    image: {
      type: String
    },
    date: {
      type: String
    },
    time: {
      type: String
    }
  },
  data() {
    return {
      flip: false
    }
  }
}
</script>

<style scoped lang="scss">
.comment {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.comment__text {
  font-size: 12px;
  text-align: justify;
  line-height: 18px;
  padding: 0 20px 5px;
}
.comment__profile {
  cursor: pointer;
  width: calc(100% - 40px);
  height: 70px;
  display: grid;
  grid-template-columns: 20px 50px auto;
  align-items: center;
  background-color: #eee;
  margin: 0;
  border-radius: 10px;
}
.comment__img {
  grid-column: 2/3;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    filter: saturate(0.3);
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
  }
}

.comment__info {
  grid-column: 3/4;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-right: 10px;
  height: 100%;
  width: 100%;
}
.comment__name {
  width: 100%;
  color: #000;
  text-align: right;
  font-size: 16px;
  margin-bottom: 6px;
}
.comment__time {
  direction: ltr;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  time {
    margin-left: 8px;
  }
}
</style>
