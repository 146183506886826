<template>
  <div class="cmp-wrapper">
    <navbar msg="نظرات" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="cmp-items-wrapper">
        <survey-comment
          v-for="comment in comments"
          :key="comment.id"
          :content="comment['text']"
          :name="comment['name']"
          :image="comment['image']"
          :date="localizeDate(comment['created_at'])"
          :time="getHour(comment['created_at'])"
        />
      </div>
    </section>
    <failed-mode @refresh="getComments" v-else />

    <fixed-footer />
  </div>
</template>

<script>
import { ref } from 'vue'
import { searchSurvey } from '@service/SurveyService'
import dateConvertor from '@composable/dateConvertor'
import SurveyComment from './subComponents/SurveyComment'

export default {
  name: 'SurveyComments',
  components: {
    SurveyComment
  },
  props: ['id'],
  setup(props) {
    const pageStatus = ref('loading')
    const comments = ref(null)
    const getComments = async () => {
      pageStatus.value = 'loading'
      try {
        const surveyOptions = { survey: props.id, has_text: true }
        const { data } = await searchSurvey(surveyOptions)
        comments.value = data.results
        pageStatus.value = 'resolved'
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }
    getComments()

    const { localizeDate, getHour } = dateConvertor()

    return { comments, localizeDate, getHour, pageStatus, getComments }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  background-color: #fff;
  padding: 60px 0 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
