<template>
  <div :class="['survey-single', isDark && 'survey--dark']">
    <div class="survey__icon">
      <svg
        width="880px"
        height="512px"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 880 512"
        class="survey__svg"
      >
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            class="fil0"
            d="M514.16 91.98c-23.55,0 -42.82,19.27 -42.82,42.82l0 242.39c0,23.55 19.27,42.83 42.82,42.83l242.39 0c23.55,0 42.82,-19.28 42.82,-42.83l0 -242.39c0,-23.55 -19.27,-42.82 -42.82,-42.82l-242.39 0zm0 27.2c-8.54,0 -15.62,7.09 -15.62,15.62l0 242.39c0,8.54 7.08,15.63 15.62,15.63l242.39 0c8.54,0 15.62,-7.09 15.62,-15.63l0 -242.39c0,-8.53 -7.08,-15.62 -15.62,-15.62l-242.39 0zm-390.71 0c-8.54,0 -15.62,7.09 -15.62,15.62l0 242.39c0,8.54 7.08,15.63 15.62,15.63l242.39 0c8.54,0 15.62,-7.09 15.62,-15.63l0 -242.39c0,-8.53 -7.08,-15.62 -15.62,-15.62l-242.39 0zm0 -27.2c-23.55,0 -42.82,19.27 -42.82,42.82l0 242.39c0,23.55 19.27,42.83 42.82,42.83l242.39 0c23.55,0 42.82,-19.28 42.82,-42.83l0 -242.39c0,-23.55 -19.27,-42.82 -42.82,-42.82l-242.39 0z"
          />
          <path
            class="fil0"
            d="M546.99 223.95l88.25 88.24 88.24 -88.24 0.08 -0.09 5.15 -5.14 47.8 -47.8 7.84 -7.85 39.22 -39.21 30.93 -30.93c5.29,-5.29 13.93,-5.29 19.22,0l0 0c5.28,5.29 5.28,13.93 0,19.22l-30.93 30.92 -39.22 39.22 -7.84 7.85 -47.8 47.8 -39.22 39.22 -0.09 0.08 -59.26 59.27c-4.17,4.17 -8.27,5.62 -13.61,5.72 -3.11,0.06 -10.21,-1.3 -13.11,-4.2l-94.86 -94.86c-5.29,-5.29 -5.29,-13.94 0,-19.22l0 0c5.28,-5.29 13.93,-5.29 19.21,0z"
          />
        </g>
      </svg>
    </div>
    <div class="survey__text-wrapper">
      <i class="survey__circle" />
      <i class="survey__rect" />
      <h5 class="survey__text" v-text="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveySingle',
  props: {
    status: {
      type: String,
      default: '1'
    },
    text: String
  },
  computed: {
    isDark() {
      return this.status !== '1'
    }
  }
}
</script>

<style lang="scss" scoped>
.survey-single {
  cursor: pointer;
  overflow: hidden;
  width: 96%;
  height: 110px;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 15px;
}
.survey__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 5px;
}
.survey__svg {
  z-index: 2;
  fill: #c69d4e;
  width: 60px;
  height: 60px;
}
.survey__text-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 10px;
}
.survey__text {
  z-index: 4;
  font-size: 16px;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.survey__circle {
  z-index: 3;
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #fff;
  // box-shadow: 10px 0 10px 1px rgba(#000, 0.08);
  top: 0;
  margin: auto 0;
  border-radius: 100%;
  bottom: 0;
  right: -6px;
}
.survey__rect {
  z-index: 2;
  position: absolute;
  width: 40px;
  height: 100%;
  background: linear-gradient(to right, #ddd 0, #fff 100%);
  top: 0;
  bottom: 0;
  right: -15px;
}
.survey--dark {
  background-color: #181818;
}
.survey--dark .survey__text {
  color: #777;
}
.survey--dark .survey__svg {
  fill: #777;
}
.survey--dark .survey__circle {
  background-color: #181818;
}
.survey--dark .survey__rect {
  background: linear-gradient(to right, #000 0, #181818 100%);
}
</style>
