import { ref } from 'vue'
import imgConvertor from './imgConvertor'
import { canvasToBlob } from 'blob-util'

const imgResizer = () => {
  // Adding necessary functions
  const { resizeImg } = imgConvertor()

  // Cropped Image
  const imgBase64 = ref('')
  const imgFile = ref(null)

  const base64ToFile = (base64str) => {
    return new Promise((resolve) => {
      const imgTag = new Image()
      imgTag.onload = async (e) => {
        const canvas = document.createElement('canvas')
        canvas.width = e.target.width
        canvas.height = e.target.height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
        const blob = await canvasToBlob(canvas, 'image/jpeg')
        blob.lastModifiedDate = new Date()
        blob.name = 'logo.jpg'
        const outputFile = new File([blob], 'logo.jpg')

        resolve(outputFile)
      }
      imgTag.src = base64str
    })
  }

  const handleSelectImg = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const img = await resizeImg(e.target.files[0], 500)
      imgBase64.value = img
      imgFile.value = await base64ToFile(img)
    }
  }

  const removeImg = () => {
    imgBase64.value = ''
    imgFile.value = null
  }

  return { imgBase64, imgFile, handleSelectImg, removeImg }
}

export default imgResizer
