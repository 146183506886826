<template>
  <section class="list">
    <survey-single
      v-for="survey in surveys"
      :key="survey.id"
      :status="survey['status']"
      :text="survey['text']"
      @click="() => enterSurvey(survey.id)"
    />
    <empty-part
      desc-top="نظرسنجی"
      desc-bottom="نظرسنجی"
      v-if="pageStatus == 'resolved' && surveys.length === 0"
    />
    <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
    <loader v-if="pageStatus == 'loading'" />
    <observer @intersect="intersected" />
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import EmptyPart from '@common/EmptyPart'
import SurveySingle from './subComponents/SurveySingle'

export default {
  components: {
    SurveySingle,
    EmptyPart
  },
  setup() {
    // Fetch Bonuses
    const store = useStore()
    store.dispatch('survey/fetchItems', { part: 'survey' })
    const surveys = computed(() => store.state.survey.items)
    const pageStatus = computed(() => store.state.survey.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('survey/fetchItems', { part: 'survey' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterSurvey = (id) => {
      router.push({ name: 'SurveyShow', params: { id } })
    }

    // Clear offers
    onBeforeUnmount(() => store.dispatch('survey/clearItems'))

    // ------- //
    return { surveys, intersected, enterSurvey, pageStatus }
  }
}
</script>
