// import { ref } from 'vue'
// import { canvasToBlob } from 'blob-util'

const imgConvertor = () => {
  // a function for resizing image
  const resizeImg = (file, maxWidth) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        const img = reader.result
        const imgTag = new Image()
        imgTag.onload = (e) => {
          const canvas = document.createElement('canvas')
          const scaleSize = maxWidth / e.target.width
          canvas.width = maxWidth
          canvas.height = e.target.height * scaleSize

          const ctx = canvas.getContext('2d')
          ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
          const encodedImg = canvas.toDataURL(e.target, 'image/jpg', 0.7)

          resolve(encodedImg)
        }
        imgTag.src = img
      }
      reader.readAsDataURL(file)
    })
  }

  return { resizeImg }
}

export default imgConvertor
