<template>
  <div class="gift-component-wrapper">
    <div class="filtered-customers">
      <span
        class="count"
        v-text="customersCount ? customersCount.toLocaleString() : 0"
      />
      <h3 class="title">نفر آماده شرکت در نظرسنجی</h3>
    </div>
    <form novalidate class="form-wrapper" @submit.prevent="submitForm">
      <base-textarea
        label="پرسش اصلی"
        pr="3px"
        width="100%"
        animateType="remove"
        v-model="formItems['surveyQ']"
        star-label
      />
      <div class="q-wrapper">
        <div
          class="q-row"
          v-for="(q, idx) in formItems['surveyQs']"
          :key="q['id']"
        >
          <span class="q-row__idx">{{ idx + 1 }})</span>
          <our-input
            :label="itemsLabel[idx]"
            animateType="remove"
            width="90%"
            v-model="q['content']"
          />
          <a class="q-row__delete" @click="() => removeQ(q['id'])">&times;</a>
        </div>
        <a
          class="circleBtn"
          v-if="formItems['surveyQs'].length < 12"
          @click="addQ"
        >
          <svg viewBox="0 0 512 512" class="circleBtn__img">
            <path
              d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
            <path
              d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
            />
          </svg>
        </a>
      </div>
      <!-- img -->
      <a class="img">
        <img class="img__img" v-if="imgBase64" :src="imgBase64" />
        <input
          class="img__input"
          type="file"
          name="file-input"
          accept="image/*"
          @change="handleSelectImg"
        />
        <div class="img__outer" v-if="!imgBase64">
          <div class="img__inner">
            <svg class="img__icon" viewBox="0 0 512 512">
              <path
                d="M446.575,0H65.425C29.349,0,0,29.35,0,65.426v381.149C0,482.65,29.349,512,65.425,512h381.15
			C482.651,512,512,482.65,512,446.574V65.426C512,29.35,482.651,0,446.575,0z M481.842,446.575
			c0,19.447-15.821,35.267-35.267,35.267H65.425c-19.447,0-35.268-15.821-35.268-35.267v-55.007l99.255-84.451
			c3.622-3.082,8.906-3.111,12.562-0.075l62.174,51.628c5.995,4.977,14.795,4.569,20.304-0.946L372.181,209.77
			c2.67-2.675,5.783-2.935,7.408-2.852c1.62,0.083,4.695,0.661,7.078,3.596l95.176,117.19V446.575z M481.842,279.865l-71.766-88.366
			c-7.117-8.764-17.666-14.122-28.942-14.701c-11.268-0.57-22.317,3.672-30.294,11.662L212.832,326.681l-51.59-42.839
			c-14.959-12.422-36.563-12.293-51.373,0.308l-79.712,67.822V65.426c0-19.447,15.821-35.268,35.268-35.268h381.15
			c19.447,0,35.267,15.821,35.267,35.268V279.865z"
              />
              <path
                d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713
			S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556
			c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
              />
            </svg>
          </div>
        </div>
        <button
          class="img__remove"
          v-if="imgBase64"
          type="button"
          @click="removeImg"
        >
          &times;
        </button>
      </a>
      <div class="credit-buttons">
        <a class="button button--form" @click="modals['day'] = true">
          <span
            :class="['button__text', !!formItems['surveyTime'] && 'active']"
          >
            {{ btnDay }}
          </span>
          <span class="button__text" v-show="formItems['surveyTime'] > 1"
            >روز</span
          >
        </a>
        <!-- <div class="table table--form" style="width: 100%">
          <div class="table__row">
            <h6 class="table__cell cell--title">
              تعداد مشتری
            </h6>
            <h6 class="table__cell cell--amount">
              {{ customersCount.toLocaleString() }}
            </h6>
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell cell--title">
              ارسال هر نظرسنجی
            </h6>
            <h6 class="table__cell cell--price ">
              {{ surveyTablePrice.toLocaleString() }}
            </h6>
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell cell--title">
              هزینه کل
            </h6>
            <h6 class="table__cell cell--price ">
              {{ finalPrice.toLocaleString() }}
            </h6>
          </div>
        </div> -->
        <button type="submit" class="button button--black">
          <span class="button__text">ارسال نظرسنجی</span>
          <spinner v-if="btnLoading" />
        </button>
      </div>
    </form>
    <modal
      :is-open="modals['day']"
      @modalClosed="modals['day'] = false"
      title="تاریخ اعتبار"
    >
      <ul class="items-wrapper">
        <li v-for="(day, idx) in days" :key="day">
          <a
            :class="{ active: formItems['surveyTime'] === day + 1 }"
            @click="
              () => {
                if (idx === 0) formItems['surveyTime'] = 1
                else formItems['surveyTime'] = day + 1
                modals['day'] = false
              }
            "
          >
            {{ day }} <span v-if="idx > 0">روز</span>
          </a>
        </li>
      </ul>
    </modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { dateMixin } from '@mixin/dateMixin'
import { addSurvey } from '@service/SurveyService'
import {
  schemaMaker,
  reqString,
  modalNumber,
  number,
  validateForms
} from '@valid/JoiValidation'
import OurInput from '@common/OurInput'
import BaseTextarea from '@common/BaseTextarea'
import imgResizer from '@composable/imgResizer'

const isFormValid = schemaMaker({
  question: reqString('پرسش اصلی'),
  questions: number(
    'تعداد گزینه‌ها',
    2,
    'حداقل می‌بایست دو گزینه برای نظرسنجی تعریف شود.'
  ),
  time: modalNumber('تاریخ انقضا', 1)
})

export default {
  name: 'SurveyNew',
  mixins: [dateMixin],
  components: {
    OurInput,
    BaseTextarea
  },
  setup() {
    const imgResizerItems = imgResizer()

    return { ...imgResizerItems }
  },
  data() {
    return {
      modals: {
        // A modal for selecting last day of voting
        day: false
      },
      formItems: {
        surveyQ: '',
        surveyQs: [
          { id: 1, content: '' },
          { id: 2, content: '' }
        ],
        surveyHasDesc: false,
        surveyTime: '',
        surveyImg: ''
      },
      currentQsId: 2,
      // Define persian label for vote items
      itemsLabel: [
        'گزینه اول',
        'گزینه دوم',
        'گزینه سوم',
        'گزینه چهارم',
        'گزینه پنجم',
        'گزینه ششم',
        'گزینه هفتم',
        'گزینه هشتم',
        'گزینه نهم',
        'گزینه دهم',
        'گزینه یازدهم',
        'گزینه دوازدهم'
      ],
      days: ['امروز', ...Array.from({ length: 30 }, (_, i) => 1 + i)],
      /** Image Part */
      // Temporary data for uploading image
      uploadedImg: null,
      btnLoading: false,
      isDone: false,
      surveyTablePrice: 500
    }
  },
  methods: {
    addQ() {
      this.currentQsId++
      this.formItems['surveyQs'].push({
        id: this.currentQsId,
        content: ''
      })
    },
    removeQ(id) {
      let { surveyQs } = this.formItems
      this.formItems['surveyQs'] = surveyQs.filter((q) => q.id !== id)
    },
    async submitForm() {
      if (this.btnLoading || this.isDone) return

      let {
        surveyQ: question,
        surveyQs,
        surveyHasDesc: desc,
        surveyTime: time
      } = this.formItems
      const questions = surveyQs.filter((q) => q.content.trim() !== '')

      const errors = validateForms(
        { question, questions: questions.length, time },
        isFormValid
      )
      if (errors && errors.length > 0) {
        for (let key of errors) {
          const message = { type: 'error', message: key }
          this.$store.dispatch('addToast', message)
        }
        return
      }

      const finalForm = { ...this.filters }
      // main question
      finalForm['text'] = question
      // answers
      for (let key in questions) {
        finalForm[`answer[${+key + 1}]`] = questions[key]['content']
      }
      // customer desc
      finalForm['include_desc'] = desc ? 1 : 0
      // time expiration
      finalForm['expired_at'] = this.generateExpireTime(time)
      // image
      if (this.imgBase64) {
        finalForm['image'] = this.imgFile
      }

      // submitting the form
      this.btnLoading = true
      try {
        await addSurvey(finalForm)
        this.isDone = true
        this.btnLoading = false
        this.$store.dispatch('addToast', {
          type: 'success',
          message: 'نظرسنجی با موفقیت ایجاد گردید.'
        })

        this.$store.dispatch('fetchHomeData')
        setTimeout(() => this.$router.push({ name: 'SurveyHome' }), 2000)
      } catch (ex) {
        switch (ex.response.status) {
          case 402:
            this.$store.dispatch('profile/handleCreditModal', {
              status: 'open',
              type: 'credit'
            })
            break
          default:
            this.addToast({
              type: 'error',
              message: 'درخواست شما موفقیت‌آمیز نبود.'
            })
        }
      } finally {
        this.btnLoading = false
      }
    }
  },
  computed: {
    ...mapState({
      filterIsFilled: (state) => state.customer.filterIsFilled,
      filters: (state) => state.customer.filterData,
      customersCount: (state) => state.customer.filteredCusotmerCount
    }),
    finalPrice() {
      return this.surveyTablePrice * this.customersCount
    },
    btnDay() {
      const day = this.formItems['surveyTime']
      if (day) {
        return day === 1 ? 'امروز' : day - 1
      } else return 'تاریخ اعتبار'
    }
  },
  created() {
    if (!this.filterIsFilled)
      this.$router.push({
        name: 'SurveyCustomerFilter',
        params: { destination: 'survey' }
      })
  }
}
</script>

<style lang="scss" scoped>
.gift-component-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}

// ----------------------- //
.q-wrapper {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 10px 0 40px;
}
.q-row {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.q-row__delete {
  width: 30px;
  height: 30px;
  line-height: 20px;
  font-size: 16px;
  padding: 4px 0 0;
  margin: 0;
  border-radius: 100%;
  color: #aaa;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  top: 4px;
  left: -5px;
}
.q-row__idx {
  position: relative;
  top: 3px;
  right: -3px;
}
.circleBtn {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  margin: 15px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.circleBtn__img {
  fill: #777;
  width: 40px;
  height: 40px;
}

// ------- comming from offernew -------- //
.img {
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  color: white;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.img__img {
  max-width: 600px;
  width: 100%;
}
.img__outer {
  // use to set height
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.img__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 100%;
  background-color: #ddd;
  pointer-events: none;
}
.img__icon {
  width: 40px;
  height: 40px;
  fill: #fff;
  pointer-events: none;
}
.img__text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
  text-align: center;
}
.img__remove {
  display: block;
  text-align: center;
  width: 100%;
  height: 50px;
  color: #777;
  z-index: 11;
  font-size: 20px;
}
.img__input {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}

// change in common css
.credit-buttons {
  width: 100%;
  flex-direction: column;
  margin: 30px 0;
}
.button--form {
  margin: 0 0 30px;
  width: 100%;
}
.button--black {
  margin: 0;
  width: 100%;
}
.form-wrapper {
  width: 90%;
}
</style>
