<template>
  <div class="cmp-wrapper">
    <navbar msg="گزینه نظرسنجی" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="customer-title">
        <p class="customer-title__top">
          مشتریانی که به گزینه زیر رأی داده‌اند
        </p>
        <h4 class="customer-title__ans">"{{ itemText }}"</h4>
        <h5 class="customer-title__number">{{ count }} نفر</h5>
      </div>
      <div class="cmp-items-wrapper">
        <offer-customer
          v-for="voter in voters"
          :key="voter.id"
          :name="voter['name']"
          :image="voter['image']"
          @click="() => enterCustomer(voter['wallet_id'])"
        />
      </div>
    </section>
    <failed-mode @refresh="getItems" v-else />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { searchSurvey } from '@service/SurveyService'
import OfferCustomer from '@view/store/offer/subComponents/OfferCustomer'

export default {
  name: 'SurveyCustomer',
  components: {
    OfferCustomer
  },
  props: ['id'],
  setup() {
    const route = useRoute()
    const store = useStore()
    const voters = ref(null)
    const count = ref(0)
    const pageStatus = ref('loading')
    const itemText = computed(() => store.state.survey.surveyItemText)

    // Initial api call
    const getItems = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await searchSurvey(route.query)
        count.value = data['count']
        voters.value = data['results']
        pageStatus.value = 'resolved'
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }
    getItems()

    // enter customer page
    const router = useRouter()
    const enterCustomer = (id) => {
      router.push({ name: 'CustomerShow', params: { id } })
    }

    return { voters, count, itemText, enterCustomer, pageStatus, getItems }
  }
}
</script>

<style lang="scss" scoped>
$dark-text-color: #333;
$dark-bg-color-primary: #080808;
$dark-bg-color-secondary: #0e0e0e;
.customer-title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 10px;
}
.customer-title__top {
  font-size: 14px;
  margin-bottom: 10px;
  color: #777;
}
.customer-title__ans {
  font-size: 20px;
  margin-bottom: 15px;
}
.customer-title__number {
  color: #777;
}

// change in common css
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
