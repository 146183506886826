<template>
  <div class="ta-wrapper" :style="{ width: width }">
    <div class="ta-data">
      <textarea
        @input="updateValue"
        required
        :value="modelValue"
        :style="{ paddingRight: pr }"
        v-bind="$attrs"
      ></textarea>
      <label
        v-if="label"
        class="staticText"
        :class="[animateType, starLabel ? 'star' : '']"
      >
        {{ label }}</label
      >
      <div class="underline" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextarea',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '80%'
    },
    animateType: {
      type: String,
      default: 'remove'
    },
    modelValue: {
      type: [String, Number]
    },
    pr: {
      type: String,
      default: '0px'
    },
    starLabel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    resize(e) {
      // e.target.style.height = 'auto'
      if (!/\n/.test(e.target.value))
        e.target.style.height = `${e.target.scrollHeight - 12}px`
      else {
        e.target.style.height = 'auto'
        e.target.style.height = `${e.target.scrollHeight}px`
      }
    },
    updateValue(e) {
      this.resize(e)
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-wrapper {
  margin: 0;
  label {
    position: absolute;
    font-size: 15px;
    top: 5px;
    right: 0;
    color: #b4b4b4;
    pointer-events: none;
    transition: all 0.5s;

    &.staticText {
      // color: black;
      // bottom: -2px;
    }
    &::before {
      position: relative;
      content: '';
      color: #c69d4e;
      font-size: 20px;
      top: 6px;
      margin-left: 3px;
    }
    &.star::before {
      content: '*';
    }
  }
  .ta-data {
    width: 100%;
    position: relative;

    textarea {
      text-align: right;
      width: 100%;
      font-size: 17px;
      resize: none;
      border: none;
      outline: none;
      color: #000;
      background-color: transparent;
      border-bottom: 1px solid #000;
      overflow: hidden;
      padding: 12px 0 0px 4px;
      box-sizing: border-box;
      min-height: 40px;
      height: 40px;
      &::placeholder {
        position: relative;
        font-size: 15px;
        color: #b4b4b4;
        bottom: 2px;
      }

      &:focus {
        ~ div.underline::before {
          transform: translateX(0);
        }
      }
      &:focus,
      &:valid {
        ~ label.decrease {
          transform: translateY(-25px);
          font-size: 15px;
          // color: #c69d4e;
        }
        ~ label.remove {
          opacity: 0;
        }
        ~ label.static {
        }
      }
    }

    .underline {
      overflow: hidden;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 2px;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        background-color: #c69d4e;
        width: 100%;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        transition: transform 0.5s ease;
      }
    }
  }
}
</style>
